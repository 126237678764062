import React, {Fragment, useCallback, useEffect, useLayoutEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Container as BaseContainer} from "components/Common";
import {BaseButton} from "components/Buttons";
import {userLogout} from "modules/actions";
import {getIsOpenMobileNavigation, isLoggedIn} from "modules/selectors";
import {Exist} from "components/Exist";
import {useLocation} from "react-router";
import media from "assets/css/media";
import logo from "assets/img/logo.svg";
import menuLogo from "assets/img/menu-logo.svg";
import launchIcon from "assets/img/launch.svg";
import {SITE_TITLE} from "modules";

const HeaderWrapper = styled.header`
	background: var(--baseBlack);
	height: 60px;
	.landing-content img {
		display: none;
	}
	img {
		width: auto;
	}
	@media (max-width: ${media.mobile}) {
		.landing-content img {
			display: block;
		}
		.landing-content button {
			visibility: hidden;
		}
	}
`;

interface IHeaderBlock {
	isMenuOpened: boolean;
	isLanding: boolean;
	toggleMobileMenuState: () => void;
}

export const HeaderBlock = ({
	isLanding = false,
	isMenuOpened,
	toggleMobileMenuState,
}: IHeaderBlock) => {
	const selector = isMenuOpened ? "mob-open" : "";
	const contentSelector = isLanding ? "landing-content" : "";

	return (
		<HeaderWrapper>
			<Content className={contentSelector}>
				<HamburgerButton onClick={toggleMobileMenuState}>
					{isMenuOpened ? <CloseIcon /> : <MenuIcon />}
				</HamburgerButton>
				<a href="https://mixer.nrl.com/">
					<img className={selector} src={logo} alt={SITE_TITLE} />
				</a>
			</Content>
		</HeaderWrapper>
	);
};

export const NavWrapper = styled.div`
	background: var(--yellowTint);
	height: 50px;
	@media (max-width: ${media.tablet}) {
		display: none;
	}
`;

export const NavWrapperLeague = styled(NavWrapper)`
	display: none;
	@media (max-width: ${media.tablet}) {
		display: block;
		width: 100%;
		a {
			color: var(--textColor1);
			text-transform: initial;
		}
	}
`;

const Content = styled.div`
	display: flex;
	margin: 10px auto;
	max-width: 1200px;
	width: 100%;
	img {
		height: 40px;
		@media (max-width: ${media.mobile}) {
			height: 35px;
		}
	}
	button.mob-open {
		display: block;
	}
	img.mob-open {
		opacity: 0;
	}
`;

const Container = styled(BaseContainer)`
	display: flex;
	justify-content: flex-start;
`;

export const DesktopNav = styled.nav`
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1200px;
	display: flex;
	width: 100%;
	> div:first-child {
		flex: 1;
		a {
			min-width: 190px;
			justify-content: center;
			@media (max-width: 1110px) {
				min-width: 120px;
			}
		}
	}
	> div {
		display: flex;
	}

	@media (max-width: ${media.tablet}) {
		display: none;
	}
`;

export const NavItemWrap = styled.div`
	flex: 1;
`;

const DesktopNavSubNav = styled.nav`
	flex: 1;
	display: flex;

	@media (max-width: ${media.tablet}) {
		display: none;
	}

	${Container} {
		height: 52px;
		justify-content: flex-start;
		padding-left: 196px;

		@media (max-width: 840px) {
			padding-left: 186px;
		}
	}
`;

const MobileNavSubNav = styled(DesktopNavSubNav)`
	display: none;
	@media (max-width: ${media.tablet}) {
		display: flex;
		background: var(--yellowTint);
		height: 50px;
		flex: inherit;
		a {
			color: var(--textColor1);
			font-size: 13px;
			text-transform: none;
			margin: 0;
		}
	}
`;

export const NavItem = styled(NavLink)`
	display: inline-flex;
	align-items: center;
	padding: 0 15px;
	margin-top: 5px;
	height: 45px;
	line-height: 45px;
	border-bottom: 5px solid var(--yellowTint);
	font-size: 16px;
	font-weight: 700;
	position: relative;

	color: var(--textColor1);

	&.active,
	&:hover {
		position: relative;
		border-bottom: 5px solid var(--textColor1);
	}

	@media (max-width: 880px) {
		font-size: 16px;
		border-bottom: none;
	}

	@media (max-width: ${media.tablet}) {
		color: var(--primaryYellow);
		display: initial;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		height: 50px;
		line-height: 50px;
		text-align: center;
		width: 33%;

		&.active,
		&:hover {
			width: 34%;
		}

		&.active::after {
			display: none;
		}
	}
`;

export const MainNavItem = styled(NavItem)<{isTeamPage: boolean}>`
	&.active,
	&:hover {
		position: relative;
		border-bottom: 5px solid;
		border-color: ${({isTeamPage}) => (isTeamPage ? "var(--textColor2)" : "var(--textColor1)")};
	}
`;

const HamburgerButton = styled(BaseButton)`
	color: var(--textColor2);
	display: none;
	width: 40px;
	height: 40px;
	align-self: center;
	align-items: center;
	justify-content: center;
	margin: 0 12px;

	.MuiSvgIcon-root {
		font-size: 24px;
	}

	@media (max-width: ${media.tablet}) {
		display: flex;
	}

	@media (max-width: ${media.large_desktop}) {
		padding-left: 20px;
	}
`;

const HamburgerButtonDesktop = styled(HamburgerButton)`
	color: var(--textColor1);
	display: block;
	height: 32px;
	margin-left: -8px;
	.MuiSvgIcon-root {
		font-size: 32px;
	}
	@media (max-width: ${media.tablet}) {
		display: none;
	}
`;

const HamburgerButtonWhite = styled(HamburgerButtonDesktop)`
	color: var(--textColor2);
	margin: 18px 0 0 18px;
`;

const MobileTextBaseStyle = `
margin: 0px;
color: var(--primaryYellow);
font-size: 14px;
font-weight: 500;
text-transform: uppercase;
height: 48px;
text-transform: uppercase;
text-align: left;
line-height: 48px;
padding: 0;`;

const LogOutButton = styled(BaseButton)`
	${MobileTextBaseStyle}
`;

const MobileMenu = styled.div`
	z-index: 31;
	--offsetTop: 0;
	background: var(--background);
	height: 100vh;
	max-width: 260px;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	top: 60px;
	left: 0;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out, visibility 0s 0.3s, pointer-events 0s 0.3s;
	visibility: hidden;
	pointer-events: none;

	@media (max-width: ${media.tablet}) {
		max-width: 100%;
	}

	a {
		border: none;
	}

	&.opened {
		transform: translateX(0);
		visibility: visible;
		pointer-events: auto;
		transition: transform 0.3s ease-in-out, visibility 0s, pointer-events 0s;

		a {
			${MobileTextBaseStyle};
			border: none;
			width: 100%;
		}

		@media (max-width: ${media.tablet}) {
			z-index: 31;
		}
	}
`;

const MobileMenuInner = styled.div`
	padding: 0 20px;
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	min-height: calc(100% - 60px);
`;

const MediaBox = styled.div`
	text-align: center;
`;

const ExternalLinksUL = styled.ul`
	margin: 0;
`;
const ExternalLinksLI = styled.li`
	${MobileTextBaseStyle};
	padding: 0;
	span {
		margin: 0 15px 0 0;
	}
`;

interface IExternalLinks {
	isAuthorized: boolean;
}

const externalSites = [
	{
		label: "NRL Fantasy",
		link: "https://fantasy.nrl.com/",
	},
	{
		label: "NRL Tipping",
		link: "https://tipping.nrl.com/",
	},
	{
		label: "NRL Home",
		link: "https://nrl.com/",
	},
	{
		label: "Ladder Predictor",
		link: "https://ladderpredictor.nrl.com/",
	},
];

const ExternalLinks: React.FC<IExternalLinks> = () => (
	<ExternalLinksUL>
		{externalSites.map((item) => (
			<ExternalLinksLI key={item.link}>
				<span>
					<a rel="noopener noreferrer" href={item.link} target="_blank">
						{item.label}
					</a>
				</span>
				<img src={launchIcon} alt={item.label} />
			</ExternalLinksLI>
		))}
	</ExternalLinksUL>
);

const MobileNav = styled.nav`
	display: flex;
	flex-flow: column;
	margin: 0 0 20px 0;
`;

const NavLinksList: React.FC = () => {
	const isAuthorized = useSelector(isLoggedIn);
	const location = useLocation();
	const isTeamPage = location.pathname.includes("/team");

	return (
		<Exist when={isAuthorized}>
			<MainNavItem to="/team" end isTeamPage={isTeamPage}>
				My Mix
			</MainNavItem>
			<MainNavItem to="/leagues" isTeamPage={isTeamPage}>
				Leagues
			</MainNavItem>
			<MainNavItem to="/leaderboard" isTeamPage={isTeamPage}>
				Rankings
			</MainNavItem>
			<MainNavItem to="/help#POINTSCORING" isTeamPage={isTeamPage}>
				Point Scoring
			</MainNavItem>
			<MainNavItem to="/help" isTeamPage={isTeamPage}>
				Help
			</MainNavItem>
		</Exist>
	);
};

const LogInOutLink: React.FC = () => {
	const isAuthorized = useSelector(isLoggedIn);
	const location = useLocation();
	const isTeamPage = location.pathname.includes("/team");
	return (
		<Fragment>
			<Exist when={isAuthorized}>
				<MainNavItem to="/account" isTeamPage={isTeamPage}>
					My Account
				</MainNavItem>
			</Exist>
			<Exist when={!isAuthorized}>
				<NavItem to="/">Log in</NavItem>
			</Exist>
		</Fragment>
	);
};

const LeaguesSubNavList: React.FC = () => (
	<MobileNavSubNav>
		<NavItem end to="/leagues">
			My Leagues
		</NavItem>
		<NavItem to="/leagues/join">Join League</NavItem>
		<NavItem to="/leagues/create">Create League</NavItem>
	</MobileNavSubNav>
);

export const Header: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isAuthorized = useSelector(isLoggedIn);
	const isOpenMobileNavigation = useSelector(getIsOpenMobileNavigation);

	const [isMenuOpened, setMobileMenuOpenState] = useState(false);
	const mobileMenuClassName = isMenuOpened ? "opened" : "";
	const toggleMobileMenuState = useCallback(() => {
		setMobileMenuOpenState(!isMenuOpened);
	}, [isMenuOpened]);

	const onLogout = useCallback(() => {
		dispatch(userLogout());
	}, [dispatch]);

	useLayoutEffect(() => {
		document.body.style.overflow = isMenuOpened ? "hidden" : "initial";
	}, [isMenuOpened]);

	useEffect(() => {
		setMobileMenuOpenState(false);
	}, [location]);

	useEffect(() => {
		setMobileMenuOpenState(isOpenMobileNavigation);
	}, [isOpenMobileNavigation]);

	return (
		<React.Fragment>
			<HeaderBlock
				isLanding={false}
				isMenuOpened={isMenuOpened}
				toggleMobileMenuState={toggleMobileMenuState}
			/>
			<NavWrapper>
				<DesktopNav>
					<div>
						<HamburgerButtonDesktop
							onClick={toggleMobileMenuState}
							aria-label="dropdown">
							{isMenuOpened ? <CloseIcon /> : <MenuIcon />}
						</HamburgerButtonDesktop>
						<NavItemWrap>
							<NavLinksList />
						</NavItemWrap>
					</div>
					<div>
						<LogInOutLink />
					</div>
				</DesktopNav>
			</NavWrapper>
			<Routes>
				<Route path="leagues/*" element={<LeaguesSubNavList />} />
			</Routes>
			<MobileMenu className={mobileMenuClassName}>
				<HamburgerButtonWhite
					className="no-mob"
					onClick={toggleMobileMenuState}
					aria-label="Menu Button">
					{isMenuOpened ? <CloseIcon /> : <MenuIcon />}
				</HamburgerButtonWhite>
				<MobileMenuInner>
					<div>
						<MediaBox>
							<img src={menuLogo} alt={SITE_TITLE} />
						</MediaBox>
						<MobileNav className="mobile-nav">
							<NavLinksList />
							<LogInOutLink />
							<ExternalLinks isAuthorized={isAuthorized} />
							<Exist when={isAuthorized}>
								<LogOutButton onClick={onLogout}>Log out</LogOutButton>
							</Exist>
						</MobileNav>
					</div>
				</MobileMenuInner>
			</MobileMenu>
		</React.Fragment>
	);
};
